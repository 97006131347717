import {Injectable} from '@angular/core';
import {ApiService} from '@core/services/api.service';
import {Observable} from 'rxjs';
import {SectorCreateRequest, SectorModel, SectorUpdateRequest, SubSectorCreateRequest, SubSectorModel, SubSectorUpdateRequest} from '@core/models/sector.model';
import {HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SectorService {

    constructor(private readonly apiService: ApiService) {
    }

    getSectors(includeSubSectors = false): Observable<SectorModel[]> {
        let params: HttpParams;

        if (includeSubSectors) {
            params = new HttpParams().set('includeSubSectors', includeSubSectors);
        }

        return this.apiService.get('api/Sector/GetSectors', params);
    }

    createSector(req: SectorCreateRequest): Observable<SectorModel> {
        return this.apiService.post('api/Sector/CreateSector', req);
    }

    updateSector(req: SectorUpdateRequest): Observable<SectorModel> {
        return this.apiService.put('api/Sector/UpdateSector', req);
    }

    deleteSector(id: number): Observable<void> {
        return this.apiService.delete(`api/Sector/DeleteSector/${id}`);
    }

    getSubSectors(sectorId: number): Observable<SubSectorModel[]> {
        const params = new HttpParams().set('sectorId', sectorId);
        return this.apiService.get('api/Sector/GetSubSectors', params);
    }

    createSubSector(req: SubSectorCreateRequest): Observable<SubSectorModel> {
        return this.apiService.post('api/Sector/CreateSubSector', req);
    }

    updateSubSector(req: SubSectorUpdateRequest): Observable<SubSectorModel> {
        return this.apiService.put('api/Sector/UpdateSubSector', req);
    }

    deleteSubSector(id: number): Observable<void> {
        return this.apiService.delete(`api/Sector/DeleteSubSector/${id}`);
    }
}
